exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archivi-tsx": () => import("./../../../src/pages/archivi.tsx" /* webpackChunkName: "component---src-pages-archivi-tsx" */),
  "component---src-pages-autori-tsx": () => import("./../../../src/pages/autori.tsx" /* webpackChunkName: "component---src-pages-autori-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/Author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/Tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

